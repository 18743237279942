<template>
    <main class="animated fadeInLeft">
        <vue-headful
                title="Lean2Grow | Consultoria e Serviços"
                description="Ajudamos líderes e empresas a elevarem a sua produtividade"
        />
        <!-- slider Area Start-->
        <div class="slider-area ">
            <!-- Mobile Menu -->
            <div class="single-slider slider-height2 d-flex align-items-center"
                 :style="{'background-image': 'url(' + require('../../assets/img/hero/contact_hero.jpg') + ')'}">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap text-center">
                                <h2>Sobre Nós</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- slider Area End-->
        <about-content></about-content>
    </main>
</template>

<script>
    import AboutContent from './AboutContent'

    export default {
        name: "About",
        metaInfo: {
            title: 'Sobre Nós',
            meta: [
                {
                    name: 'description',
                    content: 'O nosso trabalho está assente nos princípios de Mutualiy, Enablement & Growth. ' +
                        'Assim, focamos em solucoes mutuamente benéficas,...'
                }
            ]
        },
        components: {
            AboutContent
        }
    }
</script>

<style scoped>

</style>
